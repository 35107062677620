import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { DefaultInput } from "@kmb/ds";
import OutsideClickAction from "./OutsideClickHook";

import "./App.css";

import mockData from "./mock/mockData.json";

import kmblogo from "./kmblogo.jpg";
import supplaylogo from "./supplaylogo.png";
import ouidoulogo from "./ouidoulogo.jpg";
import sendIcon from "./sendIcon.svg";

import callSuggestions from "./apiCalls/callSuggestions";
import callResults from "./apiCalls/callResults";

const App = ({
	title, titleOnClick, field, placeholder, suggestionsType
}) => {
	const [ value, setValue ] = useState("");
	const [ suggestions, setSuggestions ] = useState([]);
	const [ results, setResults ] = useState([]);
	const [ showSuggestions, setShowSuggestions ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ maxResults, setMaxResults ] = useState(0);

	const minValueLength = suggestionsType === "NAME" ? 1 : 2;

	useEffect(() => {
		setSuggestions([]);
		setResults([]);
		setValue([]);
		setMaxResults(0);
	}, [ field ]);

	useEffect(() => {
		const timeout = value
		&& setTimeout(async () => {
			const res = await callSuggestions(value, suggestionsType);
			setSuggestions(res);
		}, 100);

		if (!value) {
			setShowSuggestions(false);
			setSuggestions([]);
		}

		return () => {
			if (timeout) clearTimeout(timeout);
		};
	}, [ value ]);

	const navigate = useNavigate();

	const callResultsLoading = async (data) => {
		setIsLoading(true);
		setShowSuggestions(false);
		setResults(mockData.data);
		const res = await callResults(data, field);
		setResults(res.results);
		setMaxResults(res.total);
		setIsLoading(false);
	};

	const showMoreResults = async () => {
		setIsLoading(true);
		const res = await callResults(value, field, results.length);
		setResults([ ...results, ...res.results ]);
		setIsLoading(false);
	};

	const getLastExperienceDate = (exps) => {
		// ? first, retrieve last experience, the array isnt sorted yet
		const lastExperience = exps.reduce((prev, current) => (
			(prev.date_end > current.date_end) ? prev : current
		), 0);

		if (!lastExperience) return "Dernière expérience: Jamais";

		const lastExperienceStr = `Dernière expérience: ${dayjs(lastExperience.date_end).format("YYYY-MM-DD")}`;

		const daysSinceLastExp = dayjs().diff(lastExperience.date_end, "day");
		const daysSinceLastExpPrefix = daysSinceLastExp >= 0 ? "Il y a" : "Dans";
		const daysSinceLastExpStr = ` (${daysSinceLastExpPrefix} ${Math.abs(daysSinceLastExp)} jour(s))`;

		return lastExperienceStr + daysSinceLastExpStr;
	};

	return (
		<html>
			<head>
				<title>Interface Supplay</title>
			</head>
			<body>
				<div className="App">
					<div className="titleContainer">
						<div className="pageTitle" onClick={() => navigate(titleOnClick)}><span>{title}</span></div>
						<div className="logos">
							<a href="https://www.kmblabs.com/" target="_blank" rel="noreferrer"><img src={kmblogo} alt="KMB logo" className="logo" /></a>
							<a href="https://supplay.fr/" target="_blank" rel="noreferrer"><img src={supplaylogo} alt="Supplay logo" className="logo" /></a>
							<a href="https://ouidou.fr/" target="_blank" rel="noreferrer"><img src={ouidoulogo} alt="Ouidou logo" className="logo" /></a>
						</div>
					</div>
					<div className="Content">
						<OutsideClickAction action={ () => setShowSuggestions(false)}>
							<div className="input-container">
								<div className="inputBar">
									<DefaultInput
										className={`input ${showSuggestions && suggestions?.length ? "input-with-suggestions" : ""}`}
										placeholder={placeholder}
										type="text"
										value={value}
										id="input"
										name="input"
										autocomplete={false}
										onKeyDown={async (e) => {
											if (e.key === "Enter") {
												await callResultsLoading(value);
											}
										}}
										onChange={(event) => {
											setValue(event.target.value);
											setShowSuggestions(true);
										}}
										onClickInputButton={() => {
											setValue("");
										}}
									/>
									<button className="searchButton" onClick={ async () => {
										await callResultsLoading(value);
									} }><span>Envoyer</span><img src={sendIcon}/></button>
								</div>

								<div className="suggestions-container">
									<div className="suggestions">
										{showSuggestions
											? suggestions.slice(0, 10).map((suggestion, index) => (
												<div key={`suggestion_${index}`}
													className="suggestion"
													style={index + 1 === (suggestions.length > 10 ? 10 : suggestions.length)
														? { borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", borderBottom: "1px solid black" }
														: {}}
													onClick={async () => {
														setValue(suggestion);
														setShowSuggestions(false);
														await callResultsLoading(suggestion);
													}}>{suggestion}</div>
											))
											: null}
									</div>
								</div>
							</div>
						</OutsideClickAction>

						<div className="results-container">
							{
								results && results.length
									? <div className="results">
										{results.map((result, index) => (
											<div key={`result_${index}`} className={`result ${isLoading ? "result-loading" : ""}`}>
												<div className="resultHeader">
													<div className="resultName">{!isLoading ? result.full_name : <Skeleton width={100} height={20}/>}</div>
													<div className="resultId">{!isLoading ? `(id: ${result.id})` : <Skeleton width={50} height={20}/>}</div>
													<div className="resultEmail">{!isLoading ? result.email : <Skeleton width={150} height={20}/>}</div>
												</div>
												<div className="resultContent">
													<div className="resultLastExperience">{!isLoading ? getLastExperienceDate(result.experiences) : <Skeleton width={150} height={20}/>}</div>
													<div className="resultExperiencesTitle">{!isLoading ? "Experiences: " : <Skeleton width={150} height={20}></Skeleton>}</div>
													<div className="resultExperiences">	{result.experiences.map((experience, indexExperience) => {
														const text = [
															experience.title && ` - ${experience.title}`,
															experience.title && experience.company && "/",
															experience.company
														].filter(Boolean).join(" ");

														return experience.title || experience.company
															? <div key={`experience_${indexExperience}`} className="experience">
																{!isLoading ? text
																	: <Skeleton
																		width={"40%"}
																		height={20}
																	/>}
															</div>
															: null;
													})}
													</div>
												</div>
											</div>
										))}
										{ results.length < maxResults ? <div className="more-results-button" onClick={() => {
											showMoreResults();
										}}>Afficher plus de résultats</div> : null }
									</div>
									: <div>
										{!showSuggestions && value && value.length > minValueLength
											? <div className="noResults">Pas de résultats :(</div>
											: null }
									</div>
							}
						</div>
					</div>
				</div>
			</body>
		</html>
	);
};

App.propTypes = {
	title: PropTypes.string,
	titleOnClick: PropTypes.string,
	field: PropTypes.string,
	placeholder: PropTypes.string,
	suggestionsType: PropTypes.string
};

export default App;
