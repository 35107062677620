import React from "react";
import {
	createBrowserRouter,
	RouterProvider
} from "react-router-dom";

import App from "./App";

const router = createBrowserRouter([
	{
		path: "/experiences",
		element: <App
			title="Recherche d'intérimaires par expériences"
			titleOnClick="/companies"
			field="experiences_str"
			placeholder="Préparateur de commandes, Agent d'entretien..."
			suggestionsType="JOB_TITLE"
		/>
	},
	{
		path: "/companies",
		element: <App
			title="Recherche d'intérimaires par entreprises"
			titleOnClick="/names"
			field="companies"
			placeholder="Carrefour, Auchan, Amazon..."
			suggestionsType="COMPANY"
		/>
	},
	{
		path: "/names",
		element: <App
			title="Recherche d'intérimaires par noms"
			titleOnClick="/experiences"
			field="name_or_reference"
			placeholder="Marie Dupont, Claude Martin..."
			suggestionsType="NAME"
		/>
	},
	{
		path: "/",
		element: <App
			title="Recherche d'intérimaires par expériences"
			titleOnClick="/companies"
			field="experiences_str"
			placeholder="Préparateur de commandes, Agent d'entretien..."
			suggestionsType="JOB_TITLE"
		/>
	}
]);

export default function Router() {
	return (
		<RouterProvider router={router} />
	);
}
