import { API_KEY, API_URL } from "./config";

const callResults = async (value, field, offset = 0) => {
	const url = `${API_URL}/candidats/search`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"X-Api-Key": API_KEY
		},
		body: JSON.stringify({
			[field]: value,
			debut: offset
		})
	});
	const data = await res.json();
	console.log("search: ", data);
	return data;
};

export default callResults;
