import { API_URL, API_KEY } from "./config";

const callSuggestions = async (value, field) => {
	const url = `${API_URL}/candidats/suggestions?type=${field}`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"X-Api-Key": API_KEY
		},
		body: JSON.stringify({
			query: value
		})
	});
	const data = await res.json();
	console.log("suggestions: ", data);
	return data.suggestions.map((suggestion) => suggestion.label);
};

export default callSuggestions;
